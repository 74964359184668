<template>
  <div class="login-web">
    <v-container>
      <div class="main-container">  
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="9"
          >
            <v-card
              rounded="lg"
              class="pa-9"
              :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
            >
              <h2>{{ 'login.form.title' | text }}</h2>
              <p class="mb-4">{{ 'login.form.desc' | text }}</p>
              <v-alert
                v-if="isShowLoginInfo"
                dense
                border="left"
                type="info"
              >
                Admisi STT Amanat Agung telah mengirimkan <strong>PASSWORD</strong> Anda melalui <strong>E-mail</strong>.
              </v-alert>
              <v-alert
                dismissible
                type="error"
                v-model="isShowAlert"
              >{{ alertMsg }}</v-alert>
              <form-login source="web" @handleAlert="handleAlert"/>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
          >
            <v-card
              rounded="lg"
              class="pa-9 login-web__section_add"
              :class="{'py-9 px-4 mt-4 ml-0': $vuetify.breakpoint.smAndDown}"
            >
              <p>{{ 'login.section.right.title' | text }}</p>
              <v-btn color="primary" class="btn-custom_sm my-4" large @click="goToRegister">
                {{ 'login.section.right.btn' | text}}
              </v-btn>
              <p>{{ 'login.section.right.desc' | text }}</p>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <dialog-info ref="dialogInfoToken" :info="infos"/>
  </div>
</template>

<script>
// Libs
import { get } from 'dot-prop'

// Constants
import {
  FORGOT_PASSWORD,
  REGISTER
} from '@/constants/pages'
import {
  API_TOKEN_FORGOT_PASSWORD,
} from '@/constants/apis'

// Components
import FormLogin from '@/components/form/formLogin.vue'
import DialogInfo from '@/components/common/DialogInfo.vue'

// Utils
import request from '@/utils/request'

  export default {
    name: 'LoginWeb',
    components: {
      FormLogin,
      DialogInfo
    },
    head: {
      link: [
        { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/login', id: 'canonical' },
      ]
    },
    data() {
      return {
        isShowAlert: false,
        alertMsg: '',
        infos: {
          title: '',
          desc: '',
          btn: ''
        }
      }
    },
    computed: {
      isShowLoginInfo () {
        return this.$store.state.web.isLoginInfo
      }
    },
    created() {
      const token = get(this.$route, 'query.token', '')
      if (token) {
        request(API_TOKEN_FORGOT_PASSWORD, {token}).then(res => {
          const success = res.success ? 'success' : 'fail'
          this.infos.title = `login.dialog.${success}.title`
          this.infos.desc = `login.dialog.${success}.desc`
          this.infos.btn = `login.dialog.${success}.btn`
          this.$refs.dialogInfoToken && this.$refs.dialogInfoToken.show()
          if (!res.success) {
            setTimeout(() => {
              this.$router.push({
                name: FORGOT_PASSWORD
              }).catch(() => {})
            }, 5000);
          }
        })
      }
    },
    beforeDestroy() {
      this.$store.dispatch('web/setIsLoginInfo', false)
    },
    methods: {
      goToRegister () {
        this.$router.push({
          name: REGISTER
        }).catch(() => {})
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      }
    }
  }
</script>

<style lang="scss" scoped>
.login-web {
  min-height: 500px;
  &__section_add {
    margin-left: 36px;
    text-align: center;
  }
  p {
    margin: 0;
  }
}
</style>